// extracted by mini-css-extract-plugin
export var aboutMe = "talks-module--aboutMe--76081";
export var date = "talks-module--date--539da";
export var downloadPill = "talks-module--downloadPill--0b193";
export var downloads = "talks-module--downloads--88b6f";
export var event = "talks-module--event--21c41";
export var flag = "talks-module--flag--9897e";
export var flagCzech = "talks-module--flagCzech--b6a16";
export var flagEnglish = "talks-module--flagEnglish--02ff5";
export var heading = "talks-module--heading--028c2";
export var metadata = "talks-module--metadata--d9b81";
export var talk = "talks-module--talk--77bbb";
export var talks = "talks-module--talks--d8ebf";
export var wrapper = "talks-module--wrapper--e8519";